<template>
  <div class="page-law-decalre">
    <CommonBanner></CommonBanner>
    <div class="declare-detail">
      <h4 class="title">法律声明</h4>
      <div class="paragraph">
        <h4>版权声明</h4>
        <p>本网站（包括所有资料、信息、版面设计、图案、声音、程序及其他内容）的著作权及其他权利由本公司所享有并予以保留，此种权利受到《中华人民共和国民法通则》、《中华人民共和国著作权法》、《中华人民共和国反不正当竞争法》及其他法律、法规、规范性法律文件及相关国际条约的保护，任何对上述权利的侵犯均有可能导致承担民事、行政或刑事责任。</p>
      </div>
      <div class="paragraph">
        <h4>使用条款</h4>
        <p>任何人进入、浏览和使用和上海恒链科技有限公司（以下简称“恒链”）网站即承认已阅读、理解并同意受本使用条款约束，并遵守所有适用的法律和法规。若您不同意遵从本使用条款，请勿使用恒链网站（以下简称"本网站"）。恒链有权随时更新本使用条款，您将受到更新后的使用条款的约束，因此您应经常地访问并了解本网站的使用条款。</p>
      </div>
      <div class="paragraph">
        <h4>使用限制</h4>
        <p>本网站提供的任何内容（包括但不限于数据、文字、图表、图象、声音或录象等）的版权均属于恒链或相关权利人。未经恒链或相关权利人事先的书面许可，您不得以任何方式擅自复制、再造、传播、出版、转帖、改编或陈列本网站的内容。同时，未经恒链书面许可，对于本网站上的任何内容，任何人不得在非恒链所属的服务器上做镜像。任何未经授权使用本网站的行为都将违反《中华人民共和国著作权法》和其他法律法规以及有关国际公约的规定。</p>
      </div>
       <div class="paragraph">
        <h4>内容的改变</h4>
        <p>恒链有权随时改变和更新本网站上的内容，不需要先行通知您本人。</p>
      </div>
       <div class="paragraph">
        <h4>免责声明</h4>
        <p>恒链在此声明，对您使用网站、与本网站相关的任何内容、服务或其它链接至本网站的站点、内容均不作直接、间接、法定、约定的保证。 无论在任何原因下（包括但不限于疏忽原因），对您或任何人通过使用本网站上的信息或由本网站链接的信息，或其他与本网站链接的网站信息所导致的损失或损害（包括直接、间接、特别或后果性的损失或损害，例如收入或利润之损失，电脑系统之损坏或数据丢失等后果），责任均由使用者自行承担（包括但不限于疏忽责任） 本网站内所设的网站链接是为用户提供方便，本身并不表示我们认可或承担其他网站内容或使用上的责任。由于链接的网站不在本网站的控制范围之内，任何用户通过本网站的链接浏览其他网站均会被视为不是浏览本网站，因而对从链接的网站收到的网络传送或任何其他形式的传输，本公司不承担任何责任。</p>
      </div>
       <div class="paragraph">
        <h4>隐私保护</h4>
        <p>本公司尊重用户的隐私，未经用户的同意，本公司不搜集用户的资料。对于因服务的需要而掌握的用户的电子邮件、信息和地址，本公司承诺：非经用户允许或法律要求，不向任何第三方提供。</p>
      </div>
      <div class="paragraph">
        <h4>法律适用</h4>
        <p>由于本网站而引起的一切诉讼或争议均应适用中华人民共和国法律。如中华人民共和国法律的修改使上述任何声明成为非法，将由本公司对上述声明做出修改。</p>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "../../style/mixin.scss";

.page-law-decalre {
  margin-bottom: 48px;
  .declare-detail {
    padding: 30px 24px 20px;
    background: #fff8f3;
    border-radius: 8px;
    .title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 20px;
      text-align: center;
      // color: $themeColor;
      color: #5e2905;
    }
    .paragraph {
      line-height: 20px;
      font-size: 13px;
      margin-bottom: 20px;
      color: #823c0d;
      h4 {
        font-size: 14px;
        font-weight: bold;
        line-height: 24px;
        color: #5e2905;
      }
    }
  }
}
</style>

<script>
import CommonBanner from "../../components/CommonBanner";

export default {
  components: {
    CommonBanner
  }
}
</script>